import React from "react";
import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import {Trans, useTranslation} from "react-i18next";
import EditIcon from "@mui/icons-material/Edit";
import AddressIcon from "../../../images/AddressIcon.svg";
import PickupIcon from "../../../images/PickupIcon.svg";

const AddressCard = ({
  index = 0,
  onClick,
  name,
  phoneNumber,
  addressLine1,
  addressLine2,
  houseNumber,
  district,
  postalCode,
  city,
  region,
  country,
  onEditClick,
  onRemoveClick,
  onChooseAnotherClick,
  isDesktop,
  isDeliveryAddress,
  isPickupLocation,
  isPassportRequired = false,
  isHoverDisabled = false,
  setBackToShipRequest = null,
  isLoading = false,
  isFromAddress = false,
}: any) => {
  const {t} = useTranslation();

  const addressInLine = city + ", " + region + ", " + addressLine1;

  const AddressItem = ({children}: any) => (
    <Typography component={"div"} variant="body4">
      {children}
    </Typography>
  );

  return isLoading ? (
    <Box display="flex" justifyContent="center" alignItems="center">
      <CircularProgress style={{height: "150px", width: "150px"}} />
    </Box>
  ) : (
    <Card
      data-testid={`delivery-address-card-${index}`}
      variant="outlined"
      sx={{
        boxSizing: "border-box",
        minWidth: "354px",
        height: "auto",
        left: "0px",
        top: "0px",
        background: "#FFFFFF",
        border: "1px solid #D8E6FB",
        boxShadow: "0px 0px 8px rgba(15, 74, 132, 0.1)",
        borderRadius: "8px",
        padding: "16px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        position: "relative",
        ":hover": {
          borderColor: isHoverDisabled ? "auto" : "#00BD75",
        },
        wordBreak: "break-word",
      }}
    >
      <Box
        onClick={onClick}
        component={CardContent}
        sx={{
          paddingBottom: 1,
          flexGrow: 1,
          flexShrink: 1,
          overflowY: "auto",
          cursor: onClick ? "pointer" : "auto",
        }}
      >
        <Grid
          container
          alignItems="center"
          flexWrap={"nowrap"}
          style={{
            marginLeft: "-5px",
            marginTop: "-5px",
            marginBottom: "10px",
          }}
        >
          <img
            src={isPickupLocation ? PickupIcon : AddressIcon}
            alt="Home Icon"
            width={"43px"}
            height={"43px"}
          />
          <Typography ml={"10px"} color="#98A2B3" variant="body2">
            {isFromAddress
              ? t("profile.savedAddresses.titles.fromAddress")
              : isPickupLocation
              ? t("profile.savedAddresses.titles.pickupLocation")
              : t("profile.savedAddresses.titles.courierDeliveryAddress")}
          </Typography>
        </Grid>
        {isDesktop ? (
          <>
            <Grid pb={2}>
              <Typography variant="body2">{name}</Typography>
              <Typography variant="body4" component={"div"}>
                {phoneNumber}
              </Typography>
            </Grid>
            <AddressItem>
              {addressLine1}&nbsp;{houseNumber}&nbsp;
              {addressLine2?.length > 0
                ? isPickupLocation
                  ? ""
                  : t("apt")
                : ""}
              &nbsp;{addressLine2}
            </AddressItem>
            <AddressItem>{city}</AddressItem>
            <AddressItem>{district}</AddressItem>
            <AddressItem>{region}</AddressItem>
            <AddressItem>{postalCode}</AddressItem>
            <AddressItem>{country}</AddressItem>
            {isPassportRequired && (
              <Typography component={"div"} variant="body4" color={"red"}>
                <Trans i18nKey={"delivery.passport.passportRequired"}>
                  Passport required
                </Trans>
                {onEditClick && (
                  <Link
                    onClick={onEditClick}
                    color="primary"
                    sx={{marginLeft: 1, cursor: "pointer"}}
                  >
                    <Trans i18nKey={"delivery.passport.upload"}>Upload</Trans>
                  </Link>
                )}
              </Typography>
            )}
          </>
        ) : (
          <Box sx={{paddingTop: 0}}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Grid>
                <Typography component={"div"} variant="body2">
                  {name}
                </Typography>
                <Typography component={"div"} variant="body4">
                  {phoneNumber}
                </Typography>
              </Grid>
              <Box
                onClick={(e) => {
                  e.stopPropagation();
                  if (setBackToShipRequest) setBackToShipRequest(false);
                  onEditClick();
                }}
              >
                <EditIcon sx={{color: "#6C757D", cursor: "pointer"}} />
              </Box>
            </Box>
            <Typography sx={{paddingTop: 2}} component={"div"} variant="body3">
              {addressInLine}
            </Typography>
          </Box>
        )}
      </Box>
      <Box
        sx={{
          flexShrink: 0,
          padding: 0,
          display: "flex",
          flexWrap: "wrap",
          marginX: "16px",
          justifyContent: "flex-start",
        }}
      >
        {!isPickupLocation && (
          <>
            <Typography
              data-testid={`btn-edit-delivery-address-${index}`}
              sx={{
                cursor: "pointer",
                display: isDesktop ? "auto" : "none",
                marginRight: 2,
              }}
              onClick={() => {
                if (setBackToShipRequest) setBackToShipRequest(false);
                onEditClick();
              }}
              color="primary"
              variant="body1"
            >
              <Trans i18nKey={"profile.savedAddresses.edit"}>Edit</Trans>
            </Typography>
            <Box
              sx={{
                borderLeft: "1px solid #D8E6FB",
                display: isDesktop ? "auto" : "none",
              }}
            />
          </>
        )}
        {isDeliveryAddress ? (
          <>
            <Typography
              data-testid={`btn-choose-another-delivery-address-${index}`}
              sx={{cursor: "pointer", marginLeft: !isPickupLocation ? 2 : 0}}
              onClick={onChooseAnotherClick}
              color="primary"
              variant="body1"
            >
              <Trans i18nKey={"profile.savedAddresses.chooseAnother"}>
                ChooseAnother
              </Trans>
            </Typography>
          </>
        ) : (
          <>
            <Typography
              data-testid={`btn-remove-delivery-address-${index}`}
              sx={{
                cursor: "pointer",
                marginLeft: isDesktop && !isPickupLocation ? 2 : 0,
                marginRight: 2,
              }}
              onClick={onRemoveClick}
              color="#6C757D"
              variant="body1"
            >
              <Trans i18nKey={"profile.savedAddresses.remove"}>Remove</Trans>
            </Typography>
          </>
        )}
      </Box>
    </Card>
  );
};
export default AddressCard;
