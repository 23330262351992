import React, {useEffect} from "react";
import {Toaster} from "react-hot-toast";
import Register from "../Register/pages";
import LogIn from "../LogIn/pages";
import {Navigate, useLocation, useNavigate, useRoutes} from "react-router-dom";
import Verifications from "../Verifications/pages";
import ForgotPassword from "../ForgotPassword/pages";
import ResetPassword from "../ResetPassword/pages";
import {CssBaseline, ThemeProvider} from "@mui/material";
import {appTheme} from "./theme";
import {init} from "../../i18n";
import {FacebookRedirect} from "../Facebook/FacebookRedirect";
import i18next from "i18next";
import * as Sentry from "@sentry/browser";
import {connect} from "react-redux";
import {RootState, useAppSelector} from "../store";
import GoogleAnalyticsProvider from "../common/GoogleAnalitics/googleAnalitics.provider";
import {useOrganizationConfig} from "../common/OrganizationConfig/useOrganizationConfig";
import {useVerificationSteps} from "../common/OrganizationConfig/useVerificationSteps";
import ReactPixel from "react-facebook-pixel";
import {PortalOrganizationConfigDto} from "../features/organizationConfig/organizationConfig-api";
import {ParcelModule} from "../ParcelShipments/parcel.module";
import {PurchasesModule} from "../Purchases/purchases.module";
import {DeliveryModule} from "../Delivery/delivery.module";
import {InvoicesModule} from "../Invoices/invoices.module";
import {GettingStartedModule} from "../GettingStarted/getting-started.module";
import EditPurchases from "../Purchases/EditPurchases/pages";
import Delivery from "../Delivery/pages";
import InvoiceDetails from "../Invoices/InvoiceDetails/pages";
import AllShipments from "../ParcelShipments/AllParcelShipments/pages";
import AllInvoices from "../Invoices/AllInvoices/pages";
import ParcelShipmentInfo from "../ParcelShipments/ParcelShipmentInfo/pages";
import {ProfileModule} from "../UserProfile/profile.module";
import {APIProvider} from "@vis.gl/react-google-maps";
import SelectRequestType from "../Delivery/SelectRequestType/components/selectRequestType";

Sentry.init({
  dsn: "https://b42c6f80e09ad30baf2203282da1f559@sentry.ankocorp.com/5",
  environment: process.env.REACT_APP_SENTRY_ENVIRONMENT || "development",

  replaysSessionSampleRate:
    process.env.REACT_APP_SENTRY_ENVIRONMENT == "production" ? 0.1 : 1.0,
  replaysOnErrorSampleRate: 1.0,

  tracesSampleRate: 1.0,

  integrations: [
    new Sentry.BrowserTracing(),
    new Sentry.Replay({
      maskAllInputs: false,
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
});

init(location.pathname.split("/")[1]);

const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: false, // enable logs
};

ReactPixel.init(process.env.REACT_PIXEL_ID || "", undefined, options);
ReactPixel.pageView();

interface AppComponentProps {
  accessToken: string | undefined;
  contactId: number | null | undefined;
  langCode: string;
}

const AppComponent: React.FC<AppComponentProps> = (props) => {
  const {accessToken, langCode} = props;
  const navigate = useNavigate();
  const location = useLocation();

  const isRedirectingPath = (pathname: string) => {
    return (
      !pathname.includes("reset-password") &&
      !pathname.includes("register") &&
      !pathname.includes("forgot-password") &&
      !pathname.includes("login") &&
      pathname != "/"
    );
  };

  const userState = useAppSelector((state: RootState) => state.userState);
  const {moduleConfig, isLoading} =
    useOrganizationConfig() || ({} as PortalOrganizationConfigDto);

  const moduleConfigCustomValues = moduleConfig?.customValues;

  const googleMapsApiKey = moduleConfig?.customValues?.googleMapsApiKey;

  const purchasesModule = PurchasesModule.getInstance(
    moduleConfigCustomValues?.purchases,
  );
  const parcelModule = ParcelModule.getInstance(
    moduleConfigCustomValues?.parcels,
  );
  const deliveryModule = DeliveryModule.getInstance(
    moduleConfigCustomValues?.delivery,
  );
  const invoiceModule = InvoicesModule.getInstance(
    moduleConfigCustomValues?.invoices,
  );
  const gettingStartedModule = GettingStartedModule.getInstance(
    moduleConfigCustomValues?.gettingStarted,
  );
  const profileModule = ProfileModule.getInstance(
    moduleConfigCustomValues?.profile,
  );

  const {verified} = useVerificationSteps();

  const storeQueryParamsToSessionStorage = () => {
    const queryParams = location.search.substring(1).split("&");

    for (const keyValuePair of queryParams) {
      const [name, value] = keyValuePair.split("=");
      if (name.startsWith("_")) {
        sessionStorage.setItem(name, value);
      }
    }
  };

  useEffect(() => {
    storeQueryParamsToSessionStorage();
  }, [location.search, navigate]);

  useEffect(() => {
    const currentPath = encodeURIComponent(
      location.pathname + decodeURIComponent(location.search),
    );

    if (!accessToken && isRedirectingPath(location.pathname)) {
      if (!currentPath.includes("verifications")) {
        navigate(`/${langCode}/login?return_url=${currentPath}`);
      } else {
        navigate(`/${langCode}/login`);
      }
    }

    if (
      !verified &&
      userState?.contactId &&
      !currentPath.includes("verifications") &&
      isRedirectingPath(location.pathname)
    ) {
      navigate(`/${langCode}/verifications`);
    }
  }, [accessToken, verified, userState, isRedirectingPath, navigate]);

  const mainRoutes = [
    {
      path: "/",
      element: <Navigate to={`/${langCode}/login`} />,
    },
    {
      path: "/:lang/",
      children: [
        {path: "*", element: <Navigate to="/404" />},
        {path: "facebook", element: <FacebookRedirect />},
        {path: "register", element: <Register />},
        {path: "login", element: <LogIn />, name: "login"},
        {path: "verifications", element: <Verifications />},
        {path: "forgot-password", element: <ForgotPassword />},
        {
          path: "reset-password/:userId/:code",
          element: <ResetPassword />,
        },
        ...gettingStartedModule.getPages(),
        ...purchasesModule.getPages(),
        ...parcelModule.getPages(),
        ...deliveryModule.getPages(),
        ...invoiceModule.getPages(),
        ...profileModule.getPages(),
        {
          path: "purchases/:purchaseId",
          element: <EditPurchases />,
        },
        {
          path: "delivery/:orderId/selectRequestType",
          element: <SelectRequestType />,
        },
        {
          path: "delivery/:orderIds/",
          element: <Delivery />,
        },
        {
          path: "delivery/:orderIds/:step",
          element: <Delivery />,
        },
        {path: "invoices", element: <AllInvoices />},
        {path: "parcelShipments", element: <AllShipments config={{}} />},
        {
          path: "invoices/:invoiceId/tracking/:trackingNumber",
          element: <InvoiceDetails />,
        },
        {
          path: "parcelShipments/:orderId",
          element: <ParcelShipmentInfo />,
        },
      ],
    },
  ];

  const routing = useRoutes(mainRoutes);
  return !isLoading ? (
    <GoogleAnalyticsProvider>
      <APIProvider apiKey={googleMapsApiKey}>
        <ThemeProvider theme={appTheme}>
          <CssBaseline />
          <Toaster
            position="top-right"
            containerStyle={{top: "120px", right: "80px"}}
            toastOptions={{
              style: {
                ...appTheme.typography.caption1,
                border: "1px solid #D8E6FB",
                boxShadow: "0px 0px 8px rgba(15, 74, 132, 0.1)",
                borderRadius: "16px",
                padding: "14px",
                wordBreak: "keep-all",
              },
            }}
          />
          {routing}
        </ThemeProvider>
      </APIProvider>
    </GoogleAnalyticsProvider>
  ) : (
    <div>{"...is loading"}</div>
  );
};

const mapStateToProps = (state: RootState) => ({
  accessToken: state.authState.access_token,
  contactId: state.userState.contactId,
  langCode: getLangCodeFromI18n(),
});

export const getLangCodeFromI18n = () => {
  const i18nCode = i18next?.language?.toLowerCase() ?? "en";
  const languages = ["en", "ru", "ua"];
  return languages.find((lang) => i18nCode.includes(lang)) ?? "en";
};

export default connect(mapStateToProps)(AppComponent);
