import {useLoginSubmitHandler} from "../common/utils/loginSubmitHandler";
import {toastError} from "../common/utils/toastMessages";
import {getPortalNameFromDomain} from "../common/OrganizationConfig/useOrganizationConfig";

export const useSignInWithFacebook = () => {
  const {onGoogleSubmitHandler: facebookSubmit} = useLoginSubmitHandler();
  function signInWithFacebook(token: string) {
    try {
      const getTokenCommand = {
        grantType: "portal-facebook",
        code: token,
        rememberMe: true,
        source:
          process.env.REACT_APP_PORTAL_NAME ??
          getPortalNameFromDomain() == "portal"
            ? "shopping"
            : "parcels",
      };

      facebookSubmit(getTokenCommand);
    } catch (error) {
      toastError(error, "Problem with Facebook");
    }
  }

  return {signInWithFacebook};
};
