import React, {useEffect, useState} from "react";
import {Form, Formik, FormikProps} from "formik";
import {
  AddressCardValues,
  DeliveryAddressCommandValues,
  DeliveryAddressFormFields,
} from "../../Delivery/DeliveryAddress/components/interfaces";
import Grid from "@mui/material/Unstable_Grid2";
import {useMediaQuery, useTheme} from "@mui/material";
import {Trans, useTranslation} from "react-i18next";
import {LoadingButton} from "@mui/lab";
import {useAppSelector} from "../../store";
import {toast} from "react-hot-toast";
import {
  useContactAddressDeleteContactAddressMutation,
  useContactAddressUpdateContactAddressMutation,
} from "../../features/contactAddress/contactAddress-api";
import {
  ContactAddressDto,
  ContactDto,
  useContactCreateContactMutation,
  useLazyContactGetChildContactsQuery,
} from "../../features/contact/contact-api";
import {toastError} from "../../common/utils/toastMessages";
import AddressCard from "../../common/components/addressCard";
import AddAddressCard from "../../common/components/addAddressCard";
import {PageFragments} from "../../UserProfile/AccountSavedAddresses/pages";
import DeleteContactAddressModal from "../../UserProfile/AccountSavedAddresses/components/DeleteContactAddressModal";
import ModalForm from "../../common/Modal/components/modalForm";
import {getLangCodeFromI18n} from "../../app/app.component";
import {getLocalizedToponyms} from "../../ParcelShipments/AllParcelShipments/utils/parcelShipmentTable.utils";
import {ContactAddressValues} from "../DeliveryAddress/components/interfaces";
import {ShippingAddressFormProps} from "./components/interfaces";
import {getInitialValues} from "../DeliveryAddress/components/helpers";
import {getDeliveryAddressValidationSchema} from "../pages/validation";
import ToAddressComponent from "../DeliveryAddress/components/toAddressComponent";
import {getValidationSchemaRequiredFields} from "../../common/hooks/useFormRequiredFields";
import {CountryFilter} from "../../common/hooks/useGetCountries";

const CONTACT_ADDRESS_INITIAL_VALUES: ContactAddressValues = {
  toAddressFirstName: "",
  toAddressLastName: "",
  toAddressPhoneNumber: "",
  toAddressEmail: "",
  toAddressCountryCode: "",
  toAddressCountryName: "",
  toAddressRegionCode: "",
  toAddressRegionName: "",
  toAddressDistrict: "",
  toAddressCity: "",
  toAddressPostalCode: "",
  toAddressStreetName: "",
  toAddressHouseNumber: "",
  toAddressApartment: "",
  toAddressLatitude: null,
  toAddressLongitude: null,
  toAddressSetAsDefault: false,
  contactId: null,
  contactAddressId: null,
};

const deliveryAddressFormInitialValues = getInitialValues();

export default function ShippingAddressForm({
  pageFragment,
  editingAddress,
  setCurrentShippingAddress,
  setCurrentShippingAddressId,
  setEditingWidth,
  onChangePageState = () => 0,
  setEditingAddress,
  setCurrentAddressFormDto,
  getContactAddress,
  config,
  isFromAddress = false,
  defaultCountry = null,
}: ShippingAddressFormProps) {
  const lang = getLangCodeFromI18n();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const {t} = useTranslation();
  const userState = useAppSelector((state: any) => state.userState);

  const [currentAddress, setCurrentAddress] =
    useState<ContactAddressValues | null>(null);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [contactAddresses, setContactAddresses] =
    useState<(ContactAddressValues & AddressCardValues)[] | null>(null);
  const [triggerUpdateAddresses, setTriggerUpdateAddresses] =
    useState<boolean>(false);
  const [removeAddressModalOpen, setRemoveAddressModalOpen] =
    useState<boolean>(false);
  const [addressToRemove, setAddressToRemove] =
    useState<ContactAddressValues | null>(null);
  const [backToShipRequest, setBackToShipRequest] = useState<boolean>(true);

  const [getChildContacts] = useLazyContactGetChildContactsQuery();
  const [createContact] = useContactCreateContactMutation();
  const [updateContactAddress] =
    useContactAddressUpdateContactAddressMutation();
  const [deleteContactAddress] =
    useContactAddressDeleteContactAddressMutation();
  const [validationSchema, setValidationSchema] = useState(
    getDeliveryAddressValidationSchema("toAddress", t),
  );

  const handleSubmitError = (error: any) => {
    toastError(error);
  };
  const handleGetContactsError = (error: any): ContactDto[] => {
    toastError(error);
    return [];
  };

  const getUserContacts = async (): Promise<ContactDto[]> => {
    if (!userState?.contactId) return [];

    try {
      const getChildContactsResponse = (await getChildContacts({
        organizationId: process.env
          .REACT_APP_PORTAL_ORGANIZATION_ID as unknown as number,
        contactId: userState?.contactId,
        filter:
          config?.shippingFromAddressesFilter ?? "addressType:NOT Billing",
      })) as any;
      if (getChildContactsResponse?.error || !getChildContactsResponse?.data) {
        return handleGetContactsError(
          new Error(
            getChildContactsResponse?.error || "Failed to get child contacts",
          ),
        );
      }

      const userContacts: ContactDto[] = [];
      if (getChildContactsResponse.data?.items?.length) {
        userContacts.push(...getChildContactsResponse.data.items);
      }

      return userContacts;
    } catch (error) {
      return handleGetContactsError(error as Error);
    }
  };

  const mapContactsToFormValues = (
    contactAddresses: ContactAddressDto[],
  ): (ContactAddressValues & AddressCardValues)[] => {
    const userContactAddresses: (ContactAddressValues & AddressCardValues)[] =
      [];
    if (contactAddresses?.length) {
      for (const contactAddress of contactAddresses) {
        const [
          localizedCountryName,
          localizedStateName,
          localizedCityName,
          localizedAddressName,
        ] = getLocalizedToponyms(contactAddress, lang);

        userContactAddresses.push({
          ...CONTACT_ADDRESS_INITIAL_VALUES,
          ...{
            name: `${contactAddress.contact?.contactFirstName} ${contactAddress.contact?.contactLastName}`,
            addressLine1: `${localizedAddressName}`,
            addressLine2: `${contactAddress.addressLine2 ?? ""}`,
            houseNumber: `${
              contactAddress.customValues?.toAddressHouseNumber ?? ""
            }`,
            city: localizedCityName,
            district: contactAddress.customValues?.["toAddressDistrict"],
            region: localizedStateName,
            postalCode: contactAddress.postalCode,
            country: localizedCountryName,
            phoneNumber: contactAddress?.contact?.phoneNumber,
          },
          ...{
            toAddressFirstName: contactAddress?.contact?.contactFirstName || "",
            toAddressLastName: contactAddress?.contact?.contactLastName || "",
            toAddressPhoneNumber: contactAddress?.contact?.phoneNumber || "",
            toAddressEmail: contactAddress?.contact?.emailAddress || "",
            toAddressCountryCode: contactAddress?.countryCode || "",
            toAddressCountryName: localizedCountryName || "",
            toAddressRegionCode: contactAddress?.stateCode || "",
            toAddressRegionName: localizedStateName || "",
            toAddressDistrict:
              contactAddress?.customValues?.toAddressDistrict || "",
            toAddressCity: contactAddress?.cityName || "",
            toAddressPostalCode: contactAddress?.postalCode || "",
            toAddressStreetName: contactAddress?.addressLine || "",
            toAddressHouseNumber:
              contactAddress?.customValues?.toAddressHouseNumber || "",
            toAddressApartment:
              contactAddress?.customValues?.toAddressApartment || "",
            toAddressSetAsDefault: false,
            toAddressLatitude: contactAddress?.latitude || null,
            toAddressLongitude: contactAddress?.longitude || null,
            contactId: contactAddress?.contactId,
            contactAddressId: contactAddress?.contactAddressId,
            addressLine: contactAddress?.addressLine || "",
            isPickupLocation:
              contactAddress?.customValues?.is_pickup_location?.toLowerCase() ===
                "true" || false,
          },
        });
      }
    }
    return userContactAddresses;
  };

  const handleAddAddress = () => {
    console.log("handleAddAddress");
    onChangePageState(PageFragments.AddingAddress);
    setCurrentAddress({
      ...CONTACT_ADDRESS_INITIAL_VALUES,
      toAddressCountryCode: defaultCountry ?? "",
    });
    window.scrollTo(0, 0);
  };

  const handleEditAddress = (contactAddress: ContactAddressValues) => {
    setEditingAddress(contactAddress);
    setCurrentAddress(contactAddress);
    window.scrollTo(0, 0);
  };

  const handleRemoveAddress = (contactAddress: ContactAddressValues) => {
    setAddressToRemove(contactAddress);
    setRemoveAddressModalOpen(true);
  };

  const handleRemoveAddressConfirm = async () => {
    if (!addressToRemove) return;

    const contactAddress = addressToRemove;
    if (isSubmitting) return;

    setIsSubmitting(true);
    try {
      const deleteContactAddressResponse = await deleteContactAddress({
        organizationId: process.env
          .REACT_APP_PORTAL_ORGANIZATION_ID as unknown as number,
        contactAddressId: contactAddress.contactAddressId as number,
        deleteContactAddressCommandValues: {
          contactId: contactAddress.contactId as number,
          customerId: userState.contactId as number,
          isDefault: false,
          newDefaultId: null,
        },
      });

      if ("error" in deleteContactAddressResponse) {
        handleSubmitError(deleteContactAddressResponse.error);
        return;
      }

      toast.success(t("toasts.addressWasDeleted"));
      setTriggerUpdateAddresses((prevState) => !prevState);
    } catch (error) {
      handleSubmitError(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleCreateNewContact = (
    values: DeliveryAddressCommandValues,
  ): Promise<{
    data?: {contactId: number; contactAddresses: any[]};
    error?: any;
  }> => {
    return createContact({
      organizationId: process.env
        .REACT_APP_PORTAL_ORGANIZATION_ID as unknown as number,
      createContactCommand: {
        divisionId: process.env
          .REACT_APP_PORTAL_DIVISION_ID as unknown as number,
        values: {
          firstName: values.toAddressFirstName,
          lastName: values.toAddressLastName,
          phoneNumber: values.toAddressPhoneNumber,
          emailAddress: values.toAddressEmail,
          contactType: "Contact",
          contactLinkType: "ParentContact",
          linkToContactId: userState.contactId,
          setAsDefault:
            values.toAddressSetAsDefault || contactAddresses?.length === 0,
          shippingAddress: {
            countryCode: values.toAddressCountryCode || "",
            regionCode: values.toAddressRegionCode || "",
            cityName: values.toAddressCity || "",
            postalCode: values.toAddressPostalCode || "",
            streetName: values.toAddressStreetName || "",
            apartment: values.toAddressApartment || "",
            latitude: values.toAddressLatitude,
            longitude: values.toAddressLongitude,
            customValues: {
              toAddressHouseNumber: values?.toAddressHouseNumber,
              toAddressDistrict: values?.toAddressDistrict,
              toAddressApartment: values?.toAddressApartment,
              addressCompanyName: values?.toAddressCompanyName || "",
            },
          },
        },
      },
    }) as Promise<{data?: any; error?: any}>;
  };

  const handleUpdateContactAddress = (
    values: DeliveryAddressCommandValues,
  ): Promise<{data?: {contactId: number}; error?: any}> => {
    return updateContactAddress({
      organizationId: process.env
        .REACT_APP_PORTAL_ORGANIZATION_ID as unknown as number,
      contactAddressId: currentAddress?.contactAddressId || 0,
      updateContactAddressCommandValues: {
        addressLine: values.toAddressStreetName || "",
        addressLine2: values.toAddressApartment || "",
        cityName: values.toAddressCity || "",
        countryCode: values.toAddressCountryCode || "",
        postalCode: values.toAddressPostalCode || "",
        stateCode: values.toAddressRegionCode || "",
        firstName: values.toAddressFirstName || "",
        lastName: values.toAddressLastName || "",
        phoneNumber: values.toAddressPhoneNumber || "",
        emailAddress: values.toAddressEmail || "",
        latitude: values.toAddressLatitude || null,
        longitude: values.toAddressLongitude || null,
        customValues: {
          toAddressHouseNumber: values?.toAddressHouseNumber,
          toAddressDistrict: values?.toAddressDistrict,
          toAddressApartment: values?.toAddressApartment,
          addressCompanyName: values?.toAddressCountryName,
        },
      },
    }) as Promise<{data?: any; error?: any}>;
  };

  const handleSubmit = async (values: DeliveryAddressCommandValues) => {
    if (isSubmitting) return;
    setIsSubmitting(true);
    try {
      const isCreatingNewAddress = !currentAddress?.contactAddressId;
      let contactAddressId;
      let contactId;
      let contactAddress;
      if (isCreatingNewAddress) {
        const createContactResponse = await handleCreateNewContact(values);
        if (createContactResponse?.error) {
          handleSubmitError(createContactResponse?.error);
          return;
        } else {
          contactAddress = createContactResponse?.data?.contactAddresses?.find(
            (address) => address.addressType === "Shipping",
          );
          contactAddressId = contactAddress.contactAddressId;
          contactId = contactAddress.contactId;
        }
      } else {
        const updateContactAddressResponse = await handleUpdateContactAddress(
          values,
        );
        if (updateContactAddressResponse?.error) {
          handleSubmitError(updateContactAddressResponse?.error);
          return;
        }
      }
      toast.success(
        isCreatingNewAddress
          ? t("toasts.addressWasAdded")
          : t("toasts.addressWasUpdated"),
      );
      setTriggerUpdateAddresses((prevState) => !prevState);
      setCurrentAddress(contactAddress);
      if (contactAddressId) {
        values.contactAddressId = contactAddressId;
        values.contactId = contactId;
      }

      if (backToShipRequest) {
        if (isCreatingNewAddress)
          setCurrentShippingAddressId(contactAddressId ?? null);

        setCurrentAddressFormDto(undefined);
        if (isCreatingNewAddress) {
          setCurrentAddressFormDto(contactAddress);
        } else {
          getContactAddress(Number(values.contactAddressId)).then(
            (result: any) => {
              if (result) setCurrentAddressFormDto(result);
            },
          );
        }
      } else {
        setCurrentShippingAddressId(null);
        setCurrentAddressFormDto(null);
        setBackToShipRequest(true);
      }
      window.scroll(0, 0);
    } catch (error) {
      handleSubmitError(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    if (currentAddress) setEditingWidth(true);
    else setEditingWidth(false);
  }, [currentAddress]);

  useEffect(() => {
    getUserContacts().then((contacts) => {
      setContactAddresses(mapContactsToFormValues(contacts));
    });
  }, [userState?.contactId, triggerUpdateAddresses, lang]);

  useEffect(() => {
    if (pageFragment === 3) {
      setCurrentAddress({
        ...CONTACT_ADDRESS_INITIAL_VALUES,
        toAddressCountryCode: defaultCountry ?? "",
      });
    } else if ((pageFragment === 4 || pageFragment === 6) && editingAddress) {
      editingAddress.toAddressSetAsDefault = false;
      setCurrentAddress(editingAddress);
    } else {
      setCurrentAddress(null);
    }
  }, [pageFragment]);

  return currentAddress ? (
    <Grid
      sx={{marginX: isDesktop ? "auto" : "-16px"}}
      container
      columns={{xs: 6, md: 12}}
    >
      <Grid xs={6} md={12}>
        <Formik
          initialValues={{
            ...deliveryAddressFormInitialValues,
            ...currentAddress,
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {(formikProps) => (
            <Form>
              <ToAddressComponent
                key={"deliveryAddress"}
                showMyAddresses={false}
                formikProps={
                  formikProps as FormikProps<DeliveryAddressFormFields>
                }
                disabled={isSubmitting}
                showSaveAddressCheckbox={false}
                initialActiveCountry={
                  currentAddress?.toAddressCountryCode ?? null
                }
                setValidationSchema={setValidationSchema}
                setCurrentAddress={setCurrentAddress}
                showCompanyName={config?.addressCompanyName}
                requiredFields={getValidationSchemaRequiredFields(
                  validationSchema,
                )}
                countryFilter={CountryFilter.Default}
                disableCountrySelect={
                  config?.allowOriginCountryChange === false
                }
              />

              <Grid xs sx={{margin: "20px 0"}}>
                <LoadingButton
                  variant="contained"
                  fullWidth
                  type="submit"
                  disabled={isSubmitting || !formikProps.isValid}
                  data-testid="btn-submit"
                  color="secondary"
                >
                  <Trans i18nKey={"btnContinue"}>Continue</Trans>
                </LoadingButton>
              </Grid>
            </Form>
          )}
        </Formik>
      </Grid>
    </Grid>
  ) : (
    <Grid
      spacing={isDesktop ? 5 : 2}
      justifyContent={isDesktop ? "flex-start" : "center"}
      container
      columns={{xs: 6, md: 12}}
      sx={{
        "& .MuiCard-root": {
          height: "100%",
        },
      }}
    >
      <Grid xs={6} md={6} lg={4} height={isDesktop ? "initial" : "max-content"}>
        <AddAddressCard
          handleAddAddress={handleAddAddress}
          isDesktop={isDesktop}
        />
      </Grid>
      {contactAddresses
        ?.filter((contactAddress) => {
          return !(contactAddress as any).isPickupLocation;
        })
        .map((contactAddress, index) => {
          return (
            <Grid
              xs={6}
              md={6}
              lg={4}
              key={index}
              display={
                isSubmitting &&
                contactAddress.contactAddressId ===
                  addressToRemove?.contactAddressId
                  ? "flex"
                  : "block"
              }
              alignItems={"center"}
              justifyContent={"center"}
            >
              <AddressCard
                index={index}
                name={contactAddress.name}
                phoneNumber={contactAddress.phoneNumber}
                addressLine1={contactAddress.addressLine1}
                addressLine2={contactAddress.addressLine2}
                houseNumber={contactAddress.houseNumber}
                district={contactAddress.district}
                region={contactAddress.region}
                city={contactAddress.city}
                country={contactAddress.country}
                postalCode={contactAddress.postalCode}
                onClick={() => {
                  window.scroll(0, 0);
                  setCurrentShippingAddress(contactAddress);
                }}
                onEditClick={() => handleEditAddress(contactAddress)}
                onRemoveClick={() => handleRemoveAddress(contactAddress)}
                isDesktop={isDesktop}
                setBackToShipRequest={setBackToShipRequest}
                isLoading={
                  isSubmitting &&
                  contactAddress.contactAddressId ===
                    addressToRemove?.contactAddressId
                }
                isFromAddress={isFromAddress}
              />
            </Grid>
          );
        })}
      <ModalForm open={removeAddressModalOpen}>
        <DeleteContactAddressModal
          handleClose={() => setRemoveAddressModalOpen(false)}
          handleConfirm={() => {
            handleRemoveAddressConfirm();
            setRemoveAddressModalOpen(false);
          }}
        />
      </ModalForm>
    </Grid>
  );
}
